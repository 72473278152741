import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Home from "./pages/Home";
import About from "./pages/About";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Contact from "./pages/Contact";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsofTrade from "./pages/TermsofTrade";
import Products from "./pages/Products";
import ProductOverview from "./pages/ProductOverview";
import Downloads from "./pages/Downloads";
import Projects from "./pages/Projects";

function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/about" exact component={About} />
          <Route path="/contact" exact component={Contact} />
          <Route path="/products" exact component={ProductOverview} />
          <Route path="/products/:range" component={Products} />
          <Route path="/PrivacyPolicy" exact component={PrivacyPolicy} />
          <Route path="/TermsOfTrade" exact component={TermsofTrade} />
          <Route path="/downloads" exact component={Downloads} />
          <Route path="/projects" exact component={Projects} />
        </Switch>
        <Footer />
      </Router>
    </>
  );
}

export default App;
