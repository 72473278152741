import React from "react";
import ClothHomeText from "./ClothHomeText";
import WIHomeText from "./WIHomeText";

function HomeTextContainer() {
  return (
    <div className="home-text-container">
      <ClothHomeText />
      <WIHomeText />
    </div>
  );
}

export default HomeTextContainer;
