import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import mobileBack from "../media/icons/mobileBack.svg";
import Catalogue from "../components/ProductsList";

function Products() {
  const { range } = useParams();
  // need to be able to update these states for display, and start with default values
  const [prodRange, setProdRange] = useState(Catalogue[0]);
  const [prodTitle, setProdTitle] = useState(prodRange.title);
  const [prodPic, setProdPic] = useState(prodRange.default);

  // used for keeping the current display.
  const currentProduct = {
    title: prodTitle,
    image: prodPic,
  };

  // this ensures that this is only called once - on the component loading, not constantly updating
  useEffect(() => {
    window.scrollTo(0, 0); // always start at top of page
    // check the current range against the list of ranges to match it and show products
    for (let i = 0; i < Catalogue.length; i += 1) {
      if (range && Catalogue[i].url === range) {
        setProdRange(Catalogue[i]);
        setProdTitle(prodRange.title);
        setProdPic(prodRange.default);
        // set the title to the current range (capitalisation done via slicing)
        document.title = `${prodRange.title
          .charAt(0)
          .toUpperCase()}${prodRange.title.slice(
          1
        )} | Cloth Commercial Textiles`;
      }
    }
  }, [range, prodRange]);

  // when mouse hovers over product display, a border will appear
  function changeBorder(e) {
    e.target.style.border = "thick solid #b3a26f";
  }
  // when mouse leaves, border will dissapear
  function removeBorder(e) {
    e.target.style.border = "";
  }

  // sort the products by .key, then map them into divs. the onclick is an event listener that updates the states of the display values.
  const productImages = prodRange.range
    .sort((a, b) => a.key - b.key)
    .map((i) => (
      <div
        className="product-box"
        key={i.key}
        role="button"
        tabIndex={0}
        onKeyPress={() => {
          setProdTitle(i.title.toLowerCase());
          setProdPic(i.image);
        }}
        onClick={() => {
          setProdTitle(i.title.toLowerCase());
          setProdPic(i.image);
        }}
      >
        <img
          className="product-image"
          alt={i.title}
          src={i.image}
          onMouseEnter={changeBorder}
          onMouseLeave={removeBorder}
        />
        <div className="product-label">
          <div className="product-title">
            <h2>{i.title.toLowerCase()}</h2>
          </div>
        </div>
      </div>
    ));

  // used to keep track of whether the infobox has been clicked.
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);

  return (
    <div className="products-container">
      <div className="product-preview">
        {/* style the display to show the chosen image */}
        <div
          className="product-display"
          style={{ backgroundImage: `url('${currentProduct.image}')` }}
        />
        <div className="active-label">
          <div className="active-title">
            <h2>{currentProduct.title}</h2>
          </div>
        </div>
        {/* use the active tag to indicate it has been clicked. */}
        <div
          className={click ? "product-infobox" : "product-infobox active"}
          onClick={handleClick}
          role="button"
          onKeyPress={handleClick}
          tabIndex={0}
        >
          <p
            className={
              click ? "product-description" : "product-description active"
            }
          >
            {prodRange.description}
          </p>
          <img
            className={click ? "infobox-arrow" : "infobox-arrow active"}
            src={mobileBack}
            alt="<"
          />
        </div>
      </div>
      <Link to="/products" className="back-container">
        <img src={mobileBack} alt="<" className="backArrow" />
        <div className="productsBack">back to products</div>
      </Link>
      <div className="products-grid">{productImages}</div>
    </div>
  );
}

export default Products;
