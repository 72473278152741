import React from "react";
import AuckUni1 from "../../media/projects/cloth/projectPage/AuckUni 1.jpg";
import AuckUni2 from "../../media/projects/cloth/projectPage/AuckUni 2.jpg";
import AuckUni3 from "../../media/projects/cloth/projectPage/AuckUni 3.jpg";
import AuckUni4 from "../../media/projects/cloth/projectPage/AuckUni 4.jpg";

// function creates slides to be displayed onto home page
// sliding is done in css
function AuckUniSlides() {
  const AuckUniImages = [
    {
      images: `${AuckUni1}`,
      key: 0,
    },
    {
      images: `${AuckUni2}`,
      key: 1,
    },
    {
      images: `${AuckUni3}`,
      key: 2,
    },
    {
      images: `${AuckUni4}`,
      key: 3,
    },
    {
      images: `${AuckUni1}`,
      key: 4,
    },
    {
      images: `${AuckUni2}`,
      key: 5,
    },
    {
      images: `${AuckUni3}`,
      key: 6,
    },
    {
      images: `${AuckUni4}`,
      key: 7,
    },
  ];
  const slides = AuckUniImages.map((i) => (
    <div className="cloth-project-slide" key={i.key}>
      <img className="cloth-slide" alt="" src={i.images} />
    </div>
  ));
  return slides;
}

export default AuckUniSlides;
