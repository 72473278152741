import React from "react";

import WIProjectImages from "./WIProjectImages";

const wiProjectImages = WIProjectImages; // takes an image from cloth file array and changes it to the variable

// function creates slides to be displayed onto home page
// sliding is done in css
function WISlide() {
  const wiProjectSlides = wiProjectImages.map((slide) => (
    <div className="wi-project-slide" key={slide.key}>
      <img className="wi-slide" alt="" src={slide.images} />
    </div>
  ));
  return <div className="wi-project-slide-container">{wiProjectSlides}</div>;
}

export default WISlide;
