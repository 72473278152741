import React from "react";

function WIHomeText() {
  return (
    <div className="home-container-wrapper">
      <div className="home-wi-text-container">
        <p>
          as the official partner of woven image in new zealand, we supply a
          wide range of quality, acoustic, environmental, designer products for
          small, medium and large projects.
        </p>
        <h1>
          WOVEN
          <br />
          IMAGE
          <br />
          PARTNER
        </h1>
      </div>
    </div>
  );
}

export default WIHomeText;
