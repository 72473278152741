import React, { useEffect } from "react";
import ProjectSlideFixed from "../components/projectSlideshow/ProjectSlideFixed";
import Form from "../components/contactForm/ContactForm";

function Contact() {
  useEffect(() => {
    document.title = "Contact | Cloth Commercial Textiles";
    window.scrollTo(0, 0); // always start at top of page
  }, []);
  return (
    <div className="contact-main-container">
      <ProjectSlideFixed />
      <div className="contact-wrapper">
        <div className="contact-text-container">
          <h1>CLOTH COMMERICAL TEXTILES</h1>
          <p>
            <strong>phone:</strong> <br /> +64 9 215 7388 <br />
            <br />
            <strong>address:</strong> <br /> T/A Cloth NZ Limited <br />
            Unit D, 10 Carr Road <br />
            Mt Roskill <br />
            Auckland 1042 <br />
            <br />
            <strong>email:</strong> <br />{" "}
            <a
              className="contact-email"
              href="mailto:info@clothnz.co.nz?subject=Enquiry"
            >
              info@clothnz.co.nz
            </a>
            <br />
          </p>
        </div>
        <div>
          <Form />
        </div>
      </div>
    </div>
  );
}

export default Contact;
