import React from "react";
import NewLynn1 from "../../media/projects/cloth/projectPage/NewLynn1.jpg";
import NewLynn2 from "../../media/projects/cloth/projectPage/NewLynn2.jpg";
import NewLynn3 from "../../media/projects/cloth/projectPage/NewLynn3.jpg";

// function creates slides to be displayed onto home page
// sliding is done in css
function NewLynnSlides() {
  const NewLynnImages = [
    {
      images: `${NewLynn1}`,
      key: 0,
    },
    {
      images: `${NewLynn2}`,
      key: 1,
    },
    {
      images: `${NewLynn3}`,
      key: 2,
    },
    {
      images: `${NewLynn1}`,
      key: 3,
    },
    {
      images: `${NewLynn2}`,
      key: 4,
    },
    {
      images: `${NewLynn3}`,
      key: 5,
    },
    {
      images: `${NewLynn1}`,
      key: 6,
    },
    {
      images: `${NewLynn2}`,
      key: 7,
    },
  ];
  const slides = NewLynnImages.map((i) => (
    <div className="cloth-project-slide" key={i.key}>
      <img className="cloth-slide" alt="" src={i.images} />
    </div>
  ));
  // return <div className="cloth-project-slide-container projects">{slides}</div>;
  return slides;
}

export default NewLynnSlides;
