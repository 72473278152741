import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Catalogue from "../components/ProductsList";

function ProductOverview() {
  useEffect(() => {
    document.title = "Products | Cloth Commercial Textiles";
    window.scrollTo(0, 0); // always start at top of page
  }, []);
  // when mouse hovers over product display, a border will appear
  function changeBorder(e) {
    e.target.style.border = "thick solid #b3a26f";
  }
  // when mouse leaves box border will dissapear
  function removeBorder(e) {
    e.target.style.border = "";
  }

  const productRanges = Catalogue.sort((a, b) => a.key - b.key).map((i) => (
    <Link className="product-box" key={i.key} to={`/products/${i.url}`}>
      <img
        className="product-image"
        onMouseEnter={changeBorder}
        onMouseLeave={removeBorder}
        alt={i.title}
        src={i.default}
      />
      <div className="product-label">
        <div className="product-title overview">
          <h2>
            {i.title}:<br />
            {i.count} colours
          </h2>
        </div>
      </div>
    </Link>
  ));
  return (
    <div className="products-overview-container">
      <div className="overview-title">
        <h1>cloth product ranges</h1>
      </div>
      <div className="products-grid overview">{productRanges}</div>
    </div>
  );
}

export default ProductOverview;
