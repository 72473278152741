import React from "react";

import ClothSlide from "./ClothSlide";
import WISlide from "./WISlide";
// function calls the slide component's and implements them into the slide
// container for the home page to display
function HomeProjectSlide() {
  return (
    <div className="Project-Slide-Fixed">
      <div className="cloth-project-slider">
        <ClothSlide />
      </div>
      <div className="wi-project-slider">
        <WISlide />
      </div>
    </div>
  );
}

export default HomeProjectSlide;
