import React, { useEffect } from "react";
import ProjectSlide from "../components/projectSlideshow/ProjectSlideFixed";

function TermsofTrade() {
  useEffect(() => {
    window.scrollTo(0, 0); // always start at top of page
    document.title = "Trading Terms | Cloth Commercial Textiles";
  }, []);
  return (
    <div className="policy-container">
      <ProjectSlide />
      <div className="about-text-container">
        <h1>TRADINGS TERMS AND CONDITIONS</h1>
        <p>
          1. ACCEPTANCE <br />
          <br /> 1.1 All orders placed shall be deemed to be an acceptance of
          these terms. <br />
          1.2 There will be no variation of these Trading Terms and Conditions
          unless agreed to in writing. <br />
          1.3 No contract shall come into existence until Your request for Goods
          has been accepted by Us. <br />
          1.4 Once an order is accepted by Us. We will supply the Goods
          described in the order to You and no order placed may be cancelled or
          varied or suspended without Our approval in writing.
          <br />
          <br />
          2. COLLECTION OF INFORMATION <br />
          <br />
          2.1 You authorize Us to collect, retain and use any information about
          You, for the purpose of assessing Your credit worthiness, enforcing
          any rights under these Trading Terms and Conditions, or marketing any
          Goods provided by Us to any other party.
          <br />
          2.2 You authorize Us to disclose any information obtained to any
          person for the purposes set out in clause 2.1. <br />
          2.3 Where You are a natural person the authorities under clauses 2.1
          and 2.2 are authorities or consents for the purposes of the Privacy
          Act 1993. <br />
          <br />
          3. SECURITY <br />
          <br />
          3.1 We are entitled, at any time, to request security for the
          continuation of supply on such terms as We may determine and You must
          immediately provide such security in a form acceptable to Us. <br />
          <br />
          4. PAYMENT AND ACCOUNTS <br />
          <br />
          4.1 You will pay Us for all Goods supplied, or any other monies due
          hereunder, on the 20th of the month following delivery or earlier
          demand as the case may be. <br />
          4.2 Prices quoted and invoiced are &#39;cash prices’ for settlement by
          cash, cheque or direct credit to Our bank account. We reserve the
          right to adjust the price if payment is made by other means. <br />
          4.3 In the event You fail to make payment as set out above, You will
          pay interest on the monies due at 2% above the current base lending
          rate set from time to time by Our Banker together with any cost
          including solicitor&#39;s fees and expenses incurred by Us in recovery
          or attempted recovery of any monies due. <br />
          4.4 You will not be entitled to offset any sum, or counterclaim, of
          any kind against any amount due to Us for Goods supplied. <br />
          4.5 A credit limit may be imposed by Us at Our complete discretion and
          notified to You in writing. <br />
          <br />
          5. LIABILITY <br />
          <br />
          5.1 We are an importer and distributor of fabrics and make no
          representation or warranty, express or implied concerning the quality
          and suitability of any Goods supplied by Us. <br />
          5.2 We shall not be liable to You for any stretching, shrinking or
          loss of colour in any fabric supplied or any other error, omission, or
          negligent act, and any damages or loss of profits arising. <br />
          5.3 The above terms shall not affect any rights You may have against
          the manufacturer or other supplier of Goods which have been sold by
          Us. <br />
          5.4 You acknowledge that: <br />
          a. The Goods are being purchased for business purposes and the
          guarantees contained in the Consumer Guarantees Act 1993 are excluded.{" "}
          <br />
          b. Specifications, descriptions, measurements and other data provided
          by Us in relation to the Goods are indicative only and the Goods may
          differ from the specification provided. <br />
          c. You rely upon Your own judgment as to the nature, quality and
          condition of the Goods and the suitability of the Goods for any
          particular purpose. <br />
          d. To the maximum extent permitted by law, any warranties or
          conditions imposed on Us by the Sale of Goods Act 1908, Fair Trading
          Act 1986 and any other statute are excluded. <br />
          e. The Goods are subject to changes or modification in design or
          material at any time without notice. <br />
          f. We shall not be liable for any delay or additional expenses arising
          from any error contained in material or information provided by You or
          from any delay in You providing complete material and information.{" "}
          <br />
          5.5 We shall not be liable for any loss or damage of any kind
          whatsoever including loss of profits and consequential loss whether
          suffered or incurred by You or another person and whether in contract
          or tort (including negligence) or otherwise and irrespective of
          whether such loss or damage arises directly or indirectly from any
          defect in the Goods, any breach by Us of these Trading Terms and
          Conditions or otherwise. <br />
          5.6 In the event that We are held to be liable to You, Our liability
          shall be limited to the total price payable under an order for the
          Goods, or the cost of replacement of the Goods, whichever is less.{" "}
          <br />
          5.7 You indemnify Us against all costs, claims, demands, expenses and
          liabilities of whatsoever nature, including without prejudice to the
          generality of the foregoing, claims for death, personal injury, damage
          to property and consequential loss (including loss of profits), which
          may be made against Us or which We may sustain, pay or incur as a
          result of or in connection with the supply of Goods, unless such cost,
          claim, demand, expense or liability shall be directly and solely
          attributable to Our negligence or the negligence of our duly
          authorised employee or agent. <br />
          <br />
          6. DELIVERY <br />
          <br />
          6.1 We shall not be responsible or liable in any way to You for delays
          or defaults in delivery of Goods or for any direct or consequential
          loss or damage arising there from. <br />
          6.2 All delivery expenses of Goods supplied by Us are at Your cost.{" "}
          <br />
          6.3 Unless notified to Us in writing, Goods shall be delivered to the
          delivery address stated in the Trading Account Application. <br />
          6.4 Delivery will be deemed complete when We give physical possession
          of the Goods directly to You or to a carrier or courier. <br />
          6.5 If You refuse to accept delivery the Goods will be deemed to have
          been delivered when We were willing to deliver them. We may charge
          storage, transportation costs and all related expenses if You refuse
          to accept delivery. <br />
          <br />
          7. RISK AND RESERVATION OF TITLE
          <br />
          <br /> 7.1 The risk in any Goods supplied by Us shall pass to You on
          delivery of the Goods. <br />
          7.2 Title in any Goods supplied by Us passes to You only when You have
          made payment in full for all Goods provided by Us and of all other
          sums due to Us by You on any account whatsoever. <br />
          7.3 If the Goods are held by Us on Your behalf in accordance with
          clause 6.5 risk is deemed to have passed to You when the Goods would
          otherwise have been able to be delivered. <br />
          7.4 You hereby grant Us a security interest pursuant to the Personal
          Property Securities Act 1999 (&#34;PPSA&#34;) over the Goods and all
          of Your present and after acquired personal property as collateral for
          all monies owing by You to Us for the supply of Goods and any other
          monies that become payable under these Terms. <br />
          7.5 You shall without cost to Us store any Goods supplied by Us for
          which full payment has not been made separately from other Goods in
          Your possession and shall clearly distinguish and identify such Goods
          as Our Goods. <br />
          7.6 You agree that Our rights in respect of the Goods (and proceeds of
          the same) continue in respect of any Goods with which the Goods
          supplied become part of or are co-mingled with and extend further to
          include any moneys wherever held which are the proceeds of any sale of
          the Goods supplied prior to full payment being made in respect of the
          same by You to Us. <br />
          7.7 Where You have received payment for the Goods but have not yet
          paid Us for the same, You shall receive and hold all such proceeds of
          the sale in a separate account as a trustee for Us. <br />
          7.8 From the time of delivery you shall insure and keep insured the
          goods to the full price payable against all risks until payment in
          full for the Goods has been received by Us. <br />
          7.9 You give irrevocable authority to Us to enter any premises
          occupied by You or on which Goods are situated at any reasonable time
          after default by You or before default if We believe a default is
          likely and to remove and repossess any Goods and any other property to
          which Goods are attached or in which Goods are incorporated. We shall
          not be liable for any costs, damages, expenses or losses incurred by
          You or any third party as a result of this action, nor liable in
          contract or tort or otherwise in any way whatsoever unless by statute
          such liability cannot be excluded. We may either resell any
          repossessed Goods and credit Your account with the net proceeds of
          sale (after deduction of all repossession, storage, selling and other
          cost) or may retain any such repossessed Goods and credit Your account
          with the invoice value thereof less such sum as We reasonably
          determine on account of wear and tear, depreciation, obsolescence,
          loss of profit and costs. <br />
          7.10 You undertake that You shall not, except with the prior written
          agreement of Us, charge, mortgage, borrow against or otherwise
          encumber any of the Goods in favour of any third party until You have
          made payment in full to Us for the purchased Goods. <br />
          7.11 You agree that the provisions of sections 114(1)(a), 117(1)(c),
          120, 122, 133 and 134 of the PPSA which are for Your benefit, or place
          any obligations on Us in Your favour, shall not apply; and where We
          have rights in addition to those in Part 9 of the NZPPSA, those rights
          shall continue to apply. <br />
          7.12 You hereby waive Your rights contained in Sections 116, 119,
          120(2), 121, 125, 126, 127, 129, 131 and 132 of the PPSA and Your
          rights to receive a copy of a verification statement under section 148
          of the PPSA in respect of any financing statement of financing change
          statement registered by Us. <br />
          7.13 We may in Our discretion allocate any payment received from You
          towards any invoice that We determine and may do so at the time of
          receipt or at any time afterwards and on default by You may reallocate
          any payments previously received and allocated. In the absence of any
          payment allocation by Us, payment shall be deemed to be allocated in
          such manner as preserves the maximum value of Our purchase money
          security interest in the Goods. <br />
          7.14 You agree, if required by Us, to store the Goods separately to
          enable them to be identified. <br />
          7.15 We can register or perfect the security interest recognized under
          clause 7.4 in any means possible to ensure that We have an enforceable
          security interest against You in the Goods and all amounts due under
          the contract, including any future amounts. <br />
          7.16 You agree to promptly execute and deliver to Us all assignments,
          transfers and other agreements and documents and do anything else
          which We may deem appropriate to ensure that Our security interest
          over You is enforceable, perfected and otherwise effective, or give
          any notification in connection with the security interest to obtain
          the priority required by Us and enable Us to exercise its related
          rights, or register (and renew registration) a financing statement for
          a security interest in Our favour. <br />
          7.17 Everything We are required to do under clause 7.16 above is at
          Your expense. You agree to pay or reimburse Us for any or all of those
          costs. <br />
          <br />
          8. CLAIMS AND RETURN OF GOODS
          <br />
          <br /> 8.1 You are deemed to have accepted the condition of the Goods
          and conformity of the Goods to order unless You notify Us within 7
          days of delivery. <br />
          8.2 You shall not be entitled to return any goods supplied by Us
          except in the event that You have obtained Our written agreement to do
          so. We cannot accept claims or returns of any nature once the Goods
          have been modified (by cutting or otherwise). <br />
          8.3 In the event that We give Our agreement pursuant to paragraph 8.2
          it shall be a term of the agreement that You will pay all reasonable
          costs charged by Us including freight costs and a surcharge of 15% of
          the invoices for the Goods. <br />
          <br />
          9. QUOTATIONS
          <br />
          <br /> 9.1 We shall be entitled to add to any quotation provided any
          additional amounts including but not limited to increases: <br />
          a. Due to tariffs, duties, imports and other Government charges
          imposed on Goods supplied. <br />
          b. Due to overseas freight charges.
          <br />
          c. Due to exchange rate fluctuations. <br />
          d. By any other supplier after the date of quotation. <br />
          <br />
          10. ADDRESS FOR SERVICE OF NOTICES <br />
          <br />
          10.1 Any notice of demand given by Us to You may be served by Us
          posting such notice to Your registered office or place of business as
          stated in the Trading Account Application. <br />
          <br />
          11. DISPUTES
          <br />
          <br /> 11.1 Negotiation: If a dispute arises (Dispute) between the
          parties in relation to the supply of Goods then the parties will
          endeavour to settle the Dispute by way of good faith negotiation. If
          the parties are unable to resolve the dispute within five Working
          Days, the Dispute shall be resolved in accordance with this clause.{" "}
          <br />
          11.2 Mediation: In the event that the Dispute is not resolved by
          negotiation, the matter may be referred to mediation by either party
          by written notice. In the event that the matter is referred to
          mediation, the parties shall agree within 5 Working Days of the matter
          being referred to mediation on a mediator. In the event the Dispute is
          not resolved by a completed mediation within a further 20 Working
          Days, any party may refer the matter to arbitration. <br />
          11.3 Arbitration: If a Dispute is not settled under clauses 11.1 or
          11.2, then the Dispute shall be submitted to, and settled by,
          arbitration by a sole arbitrator in accordance with the provisions of
          the Arbitration Act 1996. The arbitrator shall be appointed by the
          parties or failing agreement within 5 Working Days after the request
          to appoint an arbitrator by one party to the other, the arbitrator
          shall be appointed by the president for the time being of the New
          Zealand Law Society. <br />
          <br />
          12. DEFAULT <br />
          <br />
          12.1 Any of the following shall constitute a default by You: <br />
          a. Non payment of any sum by the due date. <br />
          b. You intimate that You will not pay any sum by the due date. <br />
          c. Any Goods are seized by any other creditor or any other creditor
          intimates that it intends to seize Goods. <br />
          d. Any Goods in Your possession are materially damaged while any sum
          due from You to Us remains unpaid. <br />
          e. You are bankrupted or put into liquidation or a receiver is
          appointed to any of Your assets. <br />
          f. A Court judgment is entered against You and remains unsatisfied for
          7 days. <br />
          <br />
          13. TERMINATION <br />
          <br />
          13.1 On the happening of any one or more of the events set out in
          subparagraphs 13.1a - 13.1f of this clause, We shall be entitled, at
          Our sole and absolute discretion, to cease supply immediately and, if
          We choose, to terminate this agreement without notice. The events are:{" "}
          <br />
          a. Any of the defaults set out in clause 12.1 above. <br />
          b. In Our opinion You are likely to be at risk of insolvency.
          <br />
          c. The ownership or effective control of Your business is transferred
          or the nature of Your business changes materially. <br />
          d. You are in breach of any obligation in these Trading Terms and
          Conditions, or any other terms of supply, which We have notified to
          You. <br />
          e. In Our opinion You are likely to be unable to meet Your payment
          obligations to Us. <br />
          f. In Our opinion You have, or are likely to, engage in conduct that
          will damage Our reputation or any brands or other intellectual
          property owned or utilized by Us. <br />
          13.2 This agreement may be terminated by Us or You by giving 5 days
          written notice and such termination shall not affect the existing
          rights and obligations which may apply under this agreement. <br />
          13.3 On the termination of this agreement, all amounts outstanding
          under this, or any agreement with Us, will be due and must be paid
          immediately. <br />
          <br />
          14. GENERAL
          <br />
          <br /> 14.1 These Trading Terms and Conditions represent the entire
          agreement between You and Us unless they are varied by Us. We may
          amend these Trading Terms and Conditions from time to time by giving
          You written notice of such amendments. <br />
          14.2 These Trading Terms and Conditions will, in the case of conflict,
          be in priority to any other agreement with Us and any terms contained
          in any order. <br />
          14.3 Time is of the essence in Your performance of any of Your
          obligations to Us. <br />
          14.4 If any part of these Trading Terms and Conditions is held to be
          unenforceable, the part concerned will be deleted or modified to the
          minimum possible extent necessary. The remainder of these Trading
          Terms and Conditions enforceable will remain in force. <br />
          14.5 No delay or failure by Us to exercise our rights under a contract
          operates as a waiver of those rights. <br />
          14.6 You may not assign or transfer any of Your rights or obligations
          under or in connection with any contract to any third party without
          Our prior written consent. <br />
          14.7 We reserve the right to sub-contract the performance of its
          obligations or any part thereof to any other party or person. <br />
          14.8 We shall not be liable for any loss or damage caused by its
          failure or delay to supply the Goods due to anything outside Our
          reasonable control, including but not limited to acts or omissions
          breakdown of plant or machinery, shortage of labour, strikes,
          lockouts, industrial, disputes, fire or arson, storm or tempest,
          earthquakes, theft, vandalism, riots, civil commotions, terrorist
          actions, wars, government restrictions, intervention or control,
          transport delays, accidents or embargoes of any kind. <br />
          14.9 You will pay all costs and expenses (including legal fees)
          incurred by Us in exercising any of Our rights under these Trading
          Terms and Conditions. <br />
          14.10 Any notice given by one party to the other will be deemed to
          have been delivered 48 hours after posting to the recipient&#39;s
          registered office or last known address and immediately, if forwarded
          by facsimile or email. <br />
          14.11 This agreement will be governed by and construed in accordance
          with New Zealand law and any dispute or proceedings arising out of or
          in connection with this agreement will be submitted to the exclusive
          jurisdiction of the Courts of New Zealand. <br />
          <br />
          15. INTERPRETATION <br />
          <br />
          15.1 &#34;We&#34;, &#34;Us&#34;, &#34;Our&#34; means Cloth NZ Limited{" "}
          <br />
          15.2 &#34;Goods&#34; means goods or services supplied by Us to You.{" "}
          <br />
          15.3 &#34;You&#34;, &#34;Your&#34; means the Applicant or the Customer
          or the Guarantor where applicable described in the Trading Account
          Application Form. <br />
          15.4 &#34;Working Day&#34; means any day from Monday to Friday
          (inclusive) on which We are open for business.
        </p>
      </div>
    </div>
  );
}

export default TermsofTrade;
