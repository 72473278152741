/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
// boolean for recaptcha, must be true for email to be sent
let captchaValue = false;

// function for the contact form
function ContactForm() {
  // submit error state creation
  const [submitError, setSubmitError] = useState({
    submitError: "",
  });
  // submit accepted state creation
  const [submitAccepted, setSubmitAccepted] = useState({
    submitAccepted: "",
  });
  // submit button state
  const [submitButton, setSubmitButton] = useState({
    submitButton: "Submit",
  });
  // states used from the react-hook-form
  const { register, handleSubmit, errors } = useForm({
    defaultValues: {
      name: "",
      company: "",
      number: "",
      email: "",
      message: "",
    },
  });
  // sitekey for recaptcha
  const siteKey = process.env.REACT_APP_SITEKEY;
  // recaptcha onchange event
  function onChange() {
    captchaValue = true;
  }
  // recaptcha onexpired event
  function onExpired() {
    captchaValue = false;
  }
  // creation of headers for axios to use
  const axiosHeaders = { "Content-Type": "application/json" };
  // submit function for the form
  const onSubmit = (defaultValues) => {
    if (captchaValue === true) {
      setSubmitError({
        submitError: "",
      });
      setSubmitButton({ submitButton: "Sending..." });
      axios({
        headers: axiosHeaders,
        method: "POST",
        url: "https://www.clothnz.co.nz/send/", // /send for dev environment, full route for live test
        data: defaultValues,
      })
        .then(() => {
          setSubmitAccepted({
            submitAccepted:
              "Thank you for contacting us, we will reply as soon as possible",
          });
          setSubmitButton({
            submitButton: "Sent",
          });
        })
        .catch(() => {
          setSubmitError({
            submitError: "Ooops, something went wrong, please try again",
          });
          setSubmitButton({
            submitButton: "Try Again",
          });
        });
    } else {
      setSubmitError({ submitError: "Please check the ReCaptcha" });
    }
  };

  return (
    <div className="contact-form-container">
      <h1>CONTACT US</h1>
      <form className="contact-form" onSubmit={handleSubmit(onSubmit)}>
        <label className="contact-label">name</label>
        <input
          className="contact-label contact-input"
          name="name"
          placeholder="Name"
          ref={register({ required: true })}
        />
        {errors.name && (
          <p className="contact-errors">This field is required</p>
        )}

        <label className="contact-label">company</label>
        <input
          className="contact-label contact-input"
          id=""
          name="company"
          placeholder="Company"
          ref={register({ required: true })}
        />
        {errors.company && (
          <p className="contact-errors">This field is required</p>
        )}

        <label className="contact-label">number</label>
        <input
          id=""
          className="contact-label contact-input"
          name="number"
          placeholder="Number"
          ref={register({ required: true, pattern: /^\d{0,12}$/ })}
        />
        {errors.number && errors.number.type === "required" && (
          <p className="contact-errors">This field is required</p>
        )}
        {errors.number && errors.number.type === "pattern" && (
          <p className="contact-errors">Please enter a valid number</p>
        )}
        <label className="contact-label">email</label>
        <input
          id=""
          className="contact-label contact-input"
          name="email"
          placeholder="Email"
          ref={register({
            required: true,
            pattern: /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{1,3}))$/,
          })}
        />
        {errors.email && errors.email.type === "required" && (
          <p className="contact-errors">This field is required</p>
        )}
        {errors.email && errors.email.type === "pattern" && (
          <p className="contact-errors">Please enter a valid email address</p>
        )}

        <label className="contact-label">message</label>
        <textarea
          className="contact-label contact-input"
          name="message"
          placeholder="Message"
          ref={register({ required: true })}
          rows="10"
          cols="20"
        />
        {errors.message && (
          <p className="contact-errors">This field is required</p>
        )}
        <section className="contact-label">
          <ReCAPTCHA
            sitekey={siteKey}
            onChange={onChange}
            onExpired={onExpired}
            size="compact"
          />
        </section>
        <p className="contact-errors">{submitError.submitError}</p>
        <p className="contact-submit">{submitAccepted.submitAccepted}</p>
        <section className="contact-label">
          <button id="" className="submit-button" type="submit">
            {submitButton.submitButton}
          </button>
        </section>
      </form>
    </div>
  );
}

export default ContactForm;
