import React, { useEffect } from "react";
import ProjectSlide from "../components/projectSlideshow/ProjectSlideFixed";

function PrivacyPolicy() {
  useEffect(() => {
    document.title = "Pricacy Policy | Cloth Commercial Textiles";
    window.scrollTo(0, 0); // always start at top of page
  }, []);
  return (
    <div className="policy-container">
      <ProjectSlide />
      <div className="about-text-container">
        <h1>PRIVACY POLICY</h1>
        <div className="policy-text">
          We collect personal information from you, including information about
          your:
          <ul>
            <li>name</li>
            <li>email address</li>
            <li>phone number</li>
          </ul>
          <br />
          We collect your personal information in order to make contact with
          interested customers to discuss potential business.
          <br />
          <br />
          Providing some information is optional. If you choose not to enter
          your contact details, we will be unable to get in contact with you to
          discuss our services.
          <br />
          <br />
          You have the right to ask for a copy of any personal information we
          hold about you, and to ask for it to be corrected if you think it is
          wrong. If you’d like to ask for a copy of your information, or to have
          it corrected, please contact us at info@clothnz.co.nz, or +64 9 215
          7388
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
