import React, { useEffect } from "react";
import Project from "../components/projects/Project";
import AuckSlides from "../components/projectSlideshow/AuckUniSlide";
import ConnectSlides from "../components/projectSlideshow/ConnectSlide";
import NewLynnSlides from "../components/projectSlideshow/NewLynnSlide";
import UnitecSlides from "../components/projectSlideshow/UnitecSlide";
import MainfreightSlides from "../components/projectSlideshow/MainfreightSlide";

function Projects() {
  useEffect(() => {
    window.scrollTo(0, 0); // always start at top of page
    document.title = "Projects | Cloth Commercial Textiles";
  }, []);
  return (
    <div className="projects-wrapper">
      <Project
        name="auckland university"
        description="for auckland university, we supplied the material for the upholstery of the seating used throughout the campus, from offices to laboratories. these chairs used 'ultra vinyl - blue'."
        product={[4, 1]}
        rangeURL="ultra"
        pics={<AuckSlides />}
      />
      <Project
        name="unitec"
        right
        pics={<UnitecSlides />}
        rangeURL="neo-connect"
        product={[1, 10]}
        description="for unitec, we supplied the material for the upholstery of the sofas and chairs used throughout the campus. these were done in  'ranger - hillary', 'connect - tomato' and 'connect - motive' (now known as 'neo connect - motive')."
      />
      <Project
        name="connect"
        pics={<ConnectSlides />}
        rangeURL="new-gem"
        product={[2, 9]}
        description="for the connect auckland office, we provided the fabric for the screen partitions in the office. these partitions were done with' gemini - monique' and 'gemini - genesis'. we also supplied the material for the upholstery for many of the chairs there in 'ranger - shanghai'. one of our new ranges, new gem, is the successor to the gemini range, such as the similar 'new gem - onyx'."
      />
      <Project
        name="mainfreight"
        right
        pics={<MainfreightSlides />}
        product={[5, 7]}
        rangeURL="pebble"
        description="for the mainfreight auckland office, we upholstered many sofas and chairs in 'pebble vinyl - black'. we also provided the seat cushion material in 'connect - gunmetal', which is no longer available."
      />
      <Project
        name="new lynn mall"
        product={[4, 4]}
        pics={<NewLynnSlides />}
        rangeURL="ultra"
        description="at the new lynn mall, we supplied the material for the upholstery of all the seating, stools and booths. these were all done in 'ultra vinyl - taupe'."
      />
    </div>
  );
}

export default Projects;
