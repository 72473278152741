import React from "react";
import Mainfreight1 from "../../media/projects/cloth/projectPage/Mainfreight 1.jpg";
import Mainfreight2 from "../../media/projects/cloth/projectPage/Mainfreight 2.jpg";
import Mainfreight3 from "../../media/projects/cloth/projectPage/Mainfreight 3.jpg";
import Mainfreight4 from "../../media/projects/cloth/projectPage/Mainfreight 4.jpg";

// function creates slides to be displayed onto home page
// sliding is done in css
function MainfreightSlides() {
  const MainfreightImages = [
    {
      images: `${Mainfreight1}`,
      key: 0,
    },
    {
      images: `${Mainfreight2}`,
      key: 1,
    },
    {
      images: `${Mainfreight3}`,
      key: 2,
    },
    {
      images: `${Mainfreight4}`,
      key: 3,
    },
    {
      images: `${Mainfreight1}`,
      key: 4,
    },
    {
      images: `${Mainfreight2}`,
      key: 5,
    },
    {
      images: `${Mainfreight3}`,
      key: 6,
    },
    {
      images: `${Mainfreight4}`,
      key: 7,
    },
  ];
  const slides = MainfreightImages.map((i) => (
    <div className="cloth-project-slide" key={i.key}>
      <img className="cloth-slide" alt="" src={i.images} />
    </div>
  ));
  // return <div className="cloth-project-slide-container projects">{slides}</div>;
  return slides;
}

export default MainfreightSlides;
