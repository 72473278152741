import React, { useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import mobileBack from "../../media/icons/mobileBack.svg";
import Products from "../ProductsList";
import AuckSlides from "../projectSlideshow/AuckUniSlide";

function Project({ right, pics, name, description, rangeURL, product }) {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  let infoboxName = "product-infobox project";
  let titleName = "active-title";
  let containerName = "cloth-project-slide-container projects";
  if (right) {
    infoboxName = "product-infobox project right";
    containerName = "cloth-project-slide-container projects right";
    titleName = "active-title right";
  }

  return (
    <div className="project-container">
      <div className="cloth-project-slider projects">
        <div className={containerName}>{pics}</div>
      </div>
      <div className="project-info-container">
        <div
          className={
            right ? "active-label project right" : "active-label project"
          }
        >
          <div className={titleName}>
            <h2>{name.toUpperCase()}</h2>
          </div>
        </div>
        <div
          className={click ? infoboxName : `${infoboxName} active`}
          onClick={handleClick}
          onKeyPress={handleClick}
          role="button"
          tabIndex="0"
        >
          <div
            className={
              click ? "product-description" : "product-description active"
            }
          >
            {description}
            {product ? (
              <Link to={`/products/${rangeURL}`}>
                <img
                  className="project-products"
                  src={Products[product[0]].range[product[1]].image}
                  alt="the fabric used"
                />
              </Link>
            ) : (
              <div>unfortunately, we no longer stock these products.</div>
            )}
          </div>
          <img
            className={click ? "infobox-arrow" : "infobox-arrow active"}
            src={mobileBack}
            alt="<"
          />
        </div>
      </div>
    </div>
  );
}

Project.propTypes = {
  right: PropTypes.bool,
  name: PropTypes.string,
  description: PropTypes.string,
  rangeURL: PropTypes.string,
  product: PropTypes.arrayOf,
  pics: PropTypes.shape,
};
Project.defaultProps = {
  right: false,
  name: "name",
  description: "this is a project",
  rangeURL: "focus",
  product: [0, 0],
  pics: <AuckSlides />,
};

export default Project;
