import React, { useEffect } from "react";
import ProjectSlide from "../components/projectSlideshow/ProjectSlideFixed";

function About() {
  useEffect(() => {
    document.title = "About | Cloth Commercial Textiles";
    window.scrollTo(0, 0); // always start at top of page
  }, []);
  return (
    <div className="about-container">
      <ProjectSlide />
      <div className="about-text-container">
        <h1>CLOTH COMMERCIAL TEXTILES</h1>
        <p>
          Cloth is a New Zealand owned and operated company which specialises as
          a commercial textile provider of fabrics and upholstery for seating,
          screens, partitions and panels in commercial spaces.
          <br />
          <br />
          We are based in New Zealand&#39;s largest city of Auckland, and our
          work can be can be found throughout many local education, government,
          office, healthcare and hospitality facilities.
          <br />
          <br />
          Established in 2001 and originally known as FQF, Cloth has been
          supplying New Zealand businesses with high quality and durable
          textiles for the last 20 years.
          <br />
          <br />
          At Cloth Commercial Textiles, we pride ourselves in our ability to
          provide fast solutions for urgent work, thanks to the excellent stock
          holding of key products here in New Zealand. Our vision is to look
          after our customers by being easy to work with and providing top
          quality service.
        </p>
        <h1>WOVEN IMAGE PARTNER</h1>
        <p>
          Since becoming the sole distributor for Woven Image in 2015, Cloth
          imports and supplies the extensive range of Woven Image products to
          New Zealand.
          <br />
          <br />
          Woven Image is a leading interior finishes company established in 1987
          and is based out of Australia.
          <br />
          <br />
          With sustainability and design at its forefront their main products
          consist of acoustic finishes and commercial textiles. Echopanel being
          one of the leading products incorporates environmental best practices
          using recycled plastics to create the well known products today.
          <br />
          <br />
          Being subscribed to third-party verification bodies Woven Image
          products are certified with Global GreenTag GreenRate and Product
          Health Declaration, Australasia EPD and International Living Future
          Institute Red List Free and Declare Labels.
        </p>
      </div>
    </div>
  );
}

export default About;
