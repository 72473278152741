import React from "react";

function ClothHomeText() {
  return (
    <div className="home-container-wrapper">
      <div className="home-cloth-text-container">
        <h1>
          COMMERCIAL
          <br />
          TEXTILES
          <br />
          SPECIALIST
        </h1>
        <p>
          for the corporate & office environment, education facilities,
          entertainment and function rooms, auditoriums, government departments,
          healthcare, aged care and hospitality.
        </p>
      </div>
    </div>
  );
}

export default ClothHomeText;
