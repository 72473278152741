import React from "react";
import { Link } from "react-router-dom";

import wiFooterLogo from "../media/logos/wi-footer-logo.png";

function Footer() {
  function toTop() {
    window.scrollTo(0, 0); // click to go to top of page
  }
  return (
    <div className="footer-container">
      <div className="footer-container-wrapper">
        <div className="footer-links">
          <div className="footer-link-wrapper">
            <div className="footer-link-items">
              <Link to="/" className="media-remove-link">
                home
              </Link>
            </div>
          </div>
          <div className="footer-link-wrapper">
            <section className="footer-link-break-remove">|</section>
          </div>
          <div className="footer-link-wrapper">
            <div className="footer-link-items">
              <button
                onClick={toTop}
                onKeyPress={toTop}
                type="button"
                tabIndex={0}
              >
                back to top
              </button>
            </div>
          </div>
          <div className="footer-link-wrapper">
            <section className="footer-link-break">|</section>
          </div>
          <div className="footer-link-wrapper">
            <div className="footer-link-items">
              <Link to="/TermsOfTrade">terms of trade</Link>
            </div>
          </div>
          <div className="footer-link-wrapper">
            <section className="footer-link-break">|</section>
          </div>
          <div className="footer-link-wrapper">
            <div className="footer-link-items">
              <Link to="/PrivacyPolicy">privacy policy</Link>
            </div>
          </div>
        </div>
        <div className="footer-copyright">
          <div className="footer-copyright-wrapper">
            <div className="footer-copyright-items">
              <small className="website-rights">
                © 2021 ClothNZ. All rights reserved.
              </small>
            </div>

            <div className="footer-copyright-items">
              <small className="designed-by">
                Designed by
                <a
                  className="daisy-lake"
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.daisylake.co.nz"
                >
                  {" "}
                  Daisy Lake
                </a>
              </small>
            </div>
          </div>
        </div>
        <div className="footer-logo">
          <div className="footer-logo-wrapper">
            <div className="footer-logo-items">
              <a
                className="wi-logo-footer"
                href="https://www.wovenimage.com/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={wiFooterLogo} alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
