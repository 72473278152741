import echopanelLongitude269 from "../../media/projects/wovenImage/EchoPanel Longitude 269.jpg";
import echopanelLongitude330 from "../../media/projects/wovenImage/EchoPanel Longitude 330.jpg";
import echopanelWrap442 from "../../media/projects/wovenImage/EchoPanel WRAP 442 .jpg";
import echopanelWrap542 from "../../media/projects/wovenImage/EchoPanel WRAP 542.jpg";
import gem402 from "../../media/projects/wovenImage/GEM 402.jpg";
import gem551 from "../../media/projects/wovenImage/GEM 551.jpg";
import ion101 from "../../media/projects/wovenImage/ION 101.jpg";

// items in array to be used for slideshow
const WIProjectImages = [
  {
    images: `${echopanelLongitude269}`,
    key: 0,
  },
  {
    images: `${echopanelLongitude330}`,
    key: 1,
  },
  {
    images: `${echopanelWrap442}`,
    key: 2,
  },
  {
    images: `${echopanelWrap542}`,
    key: 3,
  },
  {
    images: `${gem402}`,
    key: 4,
  },
  {
    images: `${gem551}`,
    key: 5,
  },
  {
    images: `${ion101}`,
    key: 6,
  },
  {
    images: `${echopanelLongitude269}`,
    key: 7,
  },
  {
    images: `${echopanelLongitude330}`,
    key: 8,
  },
  {
    images: `${echopanelWrap442}`,
    key: 9,
  },
];

export default WIProjectImages;
