import React, { useEffect } from "react";

import ProjectSlide from "../components/projectSlideshow/ProjectSlideFixed";
import HomeTextContainer from "../components/homeText/HomeTextContainer";

function Home() {
  useEffect(() => {
    document.title = "Cloth Commercial Textiles";
    window.scrollTo(0, 0); // always start at top of page
  }, []);
  return (
    <div className="home-container">
      <ProjectSlide />
      <HomeTextContainer />
    </div>
  );
}

export default Home;
