import React from "react";
import Unitec1 from "../../media/projects/cloth/projectPage/Unitec 1.jpg";
import Unitec2 from "../../media/projects/cloth/projectPage/Unitec 2.jpg";
import Unitec3 from "../../media/projects/cloth/projectPage/Unitec 3.jpg";
import Unitec4 from "../../media/projects/cloth/projectPage/Unitec 4.jpg";
import Unitec5 from "../../media/projects/cloth/projectPage/Unitec 5.jpg";

// function creates slides to be displayed onto home page
// sliding is done in css
function UnitecSlides() {
  const UnitecImages = [
    {
      images: `${Unitec1}`,
      key: 0,
    },
    {
      images: `${Unitec2}`,
      key: 1,
    },
    {
      images: `${Unitec3}`,
      key: 2,
    },
    {
      images: `${Unitec4}`,
      key: 3,
    },
    {
      images: `${Unitec5}`,
      key: 4,
    },
    {
      images: `${Unitec1}`,
      key: 5,
    },
    {
      images: `${Unitec2}`,
      key: 6,
    },
    {
      images: `${Unitec3}`,
      key: 7,
    },
  ];
  const slides = UnitecImages.map((i) => (
    <div className="cloth-project-slide" key={i.key}>
      <img className="cloth-slide" alt="unitec example" src={i.images} />
    </div>
  ));
  // return <div className="cloth-project-slide-container projects">{slides}</div>;
  return slides;
}

export default UnitecSlides;
