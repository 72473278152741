import React from "react";
import Connect1 from "../../media/projects/cloth/projectPage/connect1.jpg";
import Connect2 from "../../media/projects/cloth/projectPage/connect2.jpg";
import Connect3 from "../../media/projects/cloth/projectPage/connect3.jpg";
import Connect4 from "../../media/projects/cloth/projectPage/connect4.jpg";

// function creates slides to be displayed onto home page
// sliding is done in css
function ConnectSlides() {
  const ConnectImages = [
    {
      images: `${Connect1}`,
      key: 0,
    },
    {
      images: `${Connect2}`,
      key: 1,
    },
    {
      images: `${Connect3}`,
      key: 2,
    },
    {
      images: `${Connect4}`,
      key: 3,
    },
    {
      images: `${Connect1}`,
      key: 4,
    },
    {
      images: `${Connect2}`,
      key: 5,
    },
    {
      images: `${Connect3}`,
      key: 6,
    },
    {
      images: `${Connect4}`,
      key: 7,
    },
  ];
  const slides = ConnectImages.map((i) => (
    <div className="cloth-project-slide" key={i.key}>
      <img className="cloth-slide" alt="" src={i.images} />
    </div>
  ));
  // return <div className="cloth-project-slide-container projects">{slides}</div>;
  return slides;
}

export default ConnectSlides;
