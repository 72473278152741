import React, { useState } from "react";
import { Link } from "react-router-dom";
import clothLogo from "../media/logos/vectorLogo.svg";
import mobileMenu from "../media/icons/mobileMenu.svg";
import mobileClose from "../media/icons/mobileClose.svg";
import mobileBack from "../media/icons/mobileBack.svg";

function Navbar() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const [prodShown, setProdShown] = useState(false);
  const toggleProd = () => setProdShown(!prodShown);
  const menuClose = () => setClick(false);
  const [WIShown, setWIShown] = useState(false);
  function swapBoth() {
    handleClick();
    toggleProd();
  }
  function menuButtonSwitch() {
    if (!prodShown && !click && !WIShown) {
      handleClick();
    } else if (!prodShown && click) {
      handleClick();
    } else if (prodShown && !click) {
      handleClick();
      setProdShown(false);
    } else if (!prodShown && !click && WIShown) {
      setProdShown(true);
      setWIShown(false);
    }
  }

  const WISubMenu = (
    <ul className={WIShown ? "nav-WI active" : "nav-WI"}>
      <li className="prod-li">
        <a
          href="https://www.wovenimage.com/brands/echopanel.html"
          className="product-links"
          target="_blank"
          rel="noreferrer"
        >
          echopanel
        </a>
      </li>
      <li className="prod-li">
        <a
          href="https://www.wovenimage.com/embossed-panels.html"
          className="product-links"
          target="_blank"
          rel="noreferrer"
        >
          embossed panel
        </a>
      </li>
      <li className="prod-li">
        <a
          href="https://www.wovenimage.com/space-dividers.html"
          className="product-links"
          target="_blank"
          rel="noreferrer"
        >
          space dividers
        </a>
      </li>
      <li className="prod-li">
        <a
          href="https://www.wovenimage.com/wallcovering.html"
          className="product-links"
          target="_blank"
          rel="noreferrer"
        >
          wall covering
        </a>
      </li>
      <li className="prod-li">
        <a
          href="https://www.wovenimage.com/ceiling-solutions.html"
          className="product-links"
          target="_blank"
          rel="noreferrer"
        >
          ceiling
        </a>
      </li>
      <li className="prod-li">
        <a
          href="https://www.wovenimage.com/workstation-screens.html"
          className="product-links"
          target="_blank"
          rel="noreferrer"
        >
          workstation screens
        </a>
      </li>
      <li className="prod-li">
        <a
          href="https://www.wovenimage.com/upholstery.html"
          className="product-links"
          target="_blank"
          rel="noreferrer"
        >
          upholstery
        </a>
      </li>
    </ul>
  );
  const productSubMenu = (
    <ul className={prodShown ? "nav-products active" : "nav-products"}>
      {window.innerWidth >= 960 ? (
        <li
          className="prod-li"
          onMouseEnter={() => setWIShown(true)}
          onMouseLeave={() => setWIShown(false)}
        >
          <a
            href="https://www.wovenimage.com/"
            className="product-links"
            target="_blank"
            rel="noreferrer"
          >
            woven image
          </a>
          {WIShown && WISubMenu}
        </li>
      ) : (
        // otherwise use li with no events, and products as a div
        <li className="prod-li">
          <a
            href="http://wovenimage.com/"
            className="product-links"
            target="_blank"
            rel="noreferrer"
          >
            woven image
          </a>
          {/* {WIShown && WISubMenu} */}
        </li>
      )}
      <li className="prod-li">
        <Link
          to="/products/new-ranger"
          className="product-links"
          role="menuitem"
          onClick={(handleClick, toggleProd)}
          onKeyPress={(handleClick, toggleProd)}
        >
          new ranger
        </Link>
      </li>
      <li className="prod-li">
        <Link
          to="/products/neo-connect"
          className="product-links"
          role="menuitem"
          onClick={(handleClick, toggleProd)}
          onKeyPress={(handleClick, toggleProd)}
        >
          neo connect
        </Link>
      </li>
      <li className="prod-li">
        <Link
          to="/products/new-gem"
          className="product-links"
          role="menuitem"
          onClick={(handleClick, toggleProd)}
          onKeyPress={(handleClick, toggleProd)}
        >
          new gem
        </Link>
      </li>
      <li className="prod-li">
        <Link
          to="/products/focus"
          className="product-links"
          role="menuitem"
          onClick={(handleClick, toggleProd)}
          onKeyPress={(handleClick, toggleProd)}
        >
          focus
        </Link>
      </li>
      <li className="prod-li">
        <Link
          to="/products/ultra"
          className="product-links"
          role="menuitem"
          onClick={(handleClick, toggleProd)}
          onKeyPress={(handleClick, toggleProd)}
        >
          ultra vinyl
        </Link>
      </li>
      <li className="prod-li">
        <Link
          to="/products/pebble"
          className="product-links"
          role="menuitem"
          onClick={(handleClick, toggleProd)}
          onKeyPress={(handleClick, toggleProd)}
        >
          pebble vinyl
        </Link>
      </li>
      <li className="prod-li">
        <Link
          to="/products/kapa"
          className="product-links"
          role="menuitem"
          onClick={(handleClick, toggleProd)}
          onKeyPress={(handleClick, toggleProd)}
        >
          kapa
        </Link>
      </li>
      <li className="prod-li">
        <Link
          to="/products/pacific"
          className="product-links"
          role="menuitem"
          onClick={(handleClick, toggleProd)}
          onKeyPress={(handleClick, toggleProd)}
        >
          pacific
        </Link>
      </li>
      <li className="prod-li">
        <Link
          to="/products/earthen"
          className="product-links"
          role="menuitem"
          onClick={(handleClick, toggleProd)}
          onKeyPress={(handleClick, toggleProd)}
        >
          earthen
        </Link>
      </li>
      <li className="prod-li">
        <Link
          to="/products/navigator"
          className="product-links"
          role="menuitem"
          onClick={(handleClick, toggleProd)}
          onKeyPress={(handleClick, toggleProd)}
        >
          navigator
        </Link>
      </li>
    </ul>
  );

  let menuButton = <img src={mobileMenu} alt="=" />;
  if ((prodShown && !click) || (WIShown && !prodShown)) {
    menuButton = <img src={mobileBack} alt="<" />;
  } else if (click && !prodShown) {
    menuButton = <img src={mobileClose} alt="X" />;
  } else {
    <img src={mobileMenu} alt="=" />;
  }

  return (
    <>
      <nav className="navbar">
        {/* the cloth logo */}
        <Link to="/" className="logo" onClick={menuClose}>
          <img src={clothLogo} alt="cloth commercial textiles" />
        </Link>

        {/* the mobile menu button */}
        <div
          className="mobile-menu-toggle"
          role="menuitem"
          tabIndex={0}
          onKeyPress={menuButtonSwitch}
          onClick={menuButtonSwitch}
        >
          {menuButton}
        </div>
        {/* the list of menu items */}
        <ul className={click ? "nav-menu active" : "nav-menu"}>
          <li className="nav-item">
            <Link to="/about" className="nav-links" onClick={menuClose}>
              about us
            </Link>
          </li>
          {/* if the window is wide, give the li the mouse events, and products as a link */}
          {window.innerWidth >= 960 ? (
            <li
              className="nav-item"
              onMouseEnter={() => setProdShown(true)}
              onMouseLeave={() => setProdShown(false)}
            >
              <Link to="/products" className="nav-links" onClick={toggleProd}>
                products
              </Link>
              {prodShown && productSubMenu}
            </li>
          ) : (
            // otherwise use li with no events, and products as a div
            <li className="nav-item">
              <div
                className="nav-links"
                role="menuitem"
                tabIndex={0}
                onKeyPress={swapBoth}
                onClick={swapBoth}
              >
                products
              </div>
              {prodShown && productSubMenu}
            </li>
          )}
          <li className="nav-item">
            <Link
              to="/projects"
              className="nav-links"
              role="menuitem"
              onKeyPress={menuClose}
              onClick={menuClose}
            >
              projects
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/downloads"
              className="nav-links"
              role="menuitem"
              onKeyPress={menuClose}
              onClick={menuClose}
            >
              downloads
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/contact"
              className="nav-links"
              role="menuitem"
              onKeyPress={menuClose}
              onClick={menuClose}
            >
              contact us
            </Link>
          </li>
        </ul>
      </nav>
    </>
  );
}

export default Navbar;
