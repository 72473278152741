// new ranger imports
import Antigua from "../media/products/new ranger/New Ranger-Antigua closeup.jpg";
import Black from "../media/products/new ranger/New Ranger-Black closeup.jpg";
import Bordeaux from "../media/products/new ranger/New Ranger-Bordeaux closeup.jpg";
import Breeze from "../media/products/new ranger/New Ranger-Breeze closeup.jpg";
import CharadeNavy from "../media/products/new ranger/New Ranger-Charade Navy closeup.jpg";
import Charcoal from "../media/products/new ranger/New Ranger-Charcoal closeup.jpg";
import Citrus from "../media/products/new ranger/New Ranger-Citrus closeup.jpg";
import ClassicSilver from "../media/products/new ranger/New Ranger-Classic Silver closeup.jpg";
import Domingo from "../media/products/new ranger/New Ranger-Domingo closeup.jpg";
import Fairway from "../media/products/new ranger/New Ranger-Fairway closeup.jpg";
import Horizon from "../media/products/new ranger/New Ranger-Horizon closeup.jpg";
import Kowhai from "../media/products/new ranger/New Ranger-Kowhai closeup.jpg";
import Natural from "../media/products/new ranger/New Ranger-Natural closeup.jpg";
import Red from "../media/products/new ranger/New Ranger-Red closeup.jpg";
import RoyalCapri from "../media/products/new ranger/New Ranger-Royal Capri closeup.jpg";
import Rustica from "../media/products/new ranger/New Ranger-Rustica closeup.jpg";
import SilverGrey from "../media/products/new ranger/New Ranger-Silver Grey closeup.jpg";
import SolarBlue from "../media/products/new ranger/New Ranger-Solar Blue closeup.jpg";
import Storm from "../media/products/new ranger/New Ranger-Storm closeup.jpg";
import Tangerine from "../media/products/new ranger/New Ranger-Tangerine closeup.jpg";
import VegasAmarillo from "../media/products/new ranger/New Ranger-Vegas Amarillo closeup.jpg";
// neo connect imports
import Cornflower from "../media/products/neo connect/New Connect - Cornflower closeup.jpg";
import Amazon from "../media/products/neo connect/New Connect-amazon venus closeup.jpg";
import Beyond from "../media/products/neo connect/New Connect-Beyond closeup.jpg";
import Chess from "../media/products/neo connect/New Connect-chess closeup.jpg";
import Clarity from "../media/products/neo connect/New Connect-clarity closeup.jpg";
import Control from "../media/products/neo connect/New Connect-control closeup.jpg";
import Gazelle from "../media/products/neo connect/New Connect-Gazelle closeup.jpg";
import Gunmetal from "../media/products/neo connect/New Connect-gunmetal closeup.jpg";
import Marlin from "../media/products/neo connect/New Connect-marlin nebular closeup.jpg";
import Motive from "../media/products/neo connect/New Connect-motive closeup.jpg";
import Smoke from "../media/products/neo connect/New Connect-Smoke closeup.jpg";
// new gem imports
import Brave from "../media/products/new gem/New Gem Brave closeup.jpg";
import Coral from "../media/products/new gem/New Gem Coral closeup.jpg";
import Emerald from "../media/products/new gem/New Gem Emerald closeup.jpg";
import Onyx from "../media/products/new gem/New Gem Onyx closeup.jpg";
import Opal from "../media/products/new gem/New Gem Opal closeup.jpg";
import Media from "../media/products/new gem/New Gem Media closeup.jpg";
import Pearl from "../media/products/new gem/New Gem Pearl closeup.jpg";
import Platinum from "../media/products/new gem/New Gem Platinum closeup.jpg";
import Sapphire from "../media/products/new gem/New Gem Sapphire closeup.jpg";
import Zest from "../media/products/new gem/New Gem Zest closeup.jpg";
// focus imports
import Navy from "../media/products/focus/Focus- Navy closeup.jpg";
import FocusTangerine from "../media/products/focus/Focus Tangerine closeup.jpg";
import Apple from "../media/products/focus/Focus-apple closeup.jpg";
import FocusCitrus from "../media/products/focus/Focus-Citrus closeup.jpg";
import Coffee from "../media/products/focus/Focus-coffee closeup.jpg";
import Diesel from "../media/products/focus/Focus-diesel closeup.jpg";
import Flame from "../media/products/focus/Focus-flame closeup.jpg";
import Gravel from "../media/products/focus/Focus-gravel closeup.jpg";
import Jet from "../media/products/focus/Focus-Jet closeup.jpg";
import Magenta from "../media/products/focus/Focus-Magenta closeup.jpg";
import Mermaid from "../media/products/focus/Focus-mermaid closeup.jpg";
import Slate from "../media/products/focus/Focus-slate closeup.jpg";
// ultra imports
import UltraRed from "../media/products/ultra/Ultra Vinyl Red closeup.jpg";
import UltraBlack from "../media/products/ultra/Ultra Vinyl Ultra Black closeup.jpg";
import Blue from "../media/products/ultra/Ultra Vinyl Ultra Blue closeup.jpg";
import UltraCharcoal from "../media/products/ultra/Ultra Vinyl Ultra Charcoal closeup.jpg";
import Cream from "../media/products/ultra/Ultra Vinyl Ultra Cream closeup.jpg";
import UltraNavy from "../media/products/ultra/Ultra Vinyl Ultra Navy closeup.jpg";
import Orange from "../media/products/ultra/Ultra Vinyl Ultra Orange closeup.jpg";
import UltraPearl from "../media/products/ultra/Ultra Vinyl Ultra Pearl closeup.jpg";
import Polar from "../media/products/ultra/Ultra Vinyl Ultra Polar closeup.jpg";
import Port from "../media/products/ultra/Ultra Vinyl Ultra Port closeup.jpg";
import Purple from "../media/products/ultra/Ultra Vinyl Ultra Purple closeup.jpg";
import Quicksilver from "../media/products/ultra/Ultra Vinyl Ultra Quicksilver closeup.jpg";
import Stingray from "../media/products/ultra/Ultra Vinyl Ultra Stingray closeup.jpg";
import Taupe from "../media/products/ultra/Ultra Vinyl Ultra Taupe closeup.jpg";
import Yellow from "../media/products/ultra/Ultra Vinyl Ultra Yellow closeup.jpg";
// pebble imports
import Beige from "../media/products/pebble/Pebble_beige.jpg";
import PebbleBlack from "../media/products/pebble/Pebble_black.jpg";
import PebbleBlue from "../media/products/pebble/Pebble_blue1.jpg";
import PebbleCharcoal from "../media/products/pebble/Pebble_charcoal1.jpg";
import Chocolate from "../media/products/pebble/Pebble_chocolate.jpg";
import PebbleCream from "../media/products/pebble/Pebble_cream1.jpg";
import PebbleGrey from "../media/products/pebble/Pebble_grey1.jpg";
import PebbleNavy from "../media/products/pebble/Pebble_navy1.jpg";
import PebblePolar from "../media/products/pebble/Pebble_polar1.jpg";
import Sand from "../media/products/pebble/Pebble_sand1.jpg";
import Tan from "../media/products/pebble/Pebble_tan.jpg";
// kapa imports
import Ash from "../media/products/kapa/Kapa Ash closeup.jpg";
import Drift from "../media/products/kapa/Kapa Drift closeup.jpg";
import Flax from "../media/products/kapa/Kapa Flax closeup.jpg";
import Ocean from "../media/products/kapa/Kapa Ocean closeup.jpg";
// pacific imports
import PacificBreeze from "../media/products/pacific/Pacific-breeze face closeup1.jpg";
import Current from "../media/products/pacific/Pacific-current closeup.jpg";
import Highway from "../media/products/pacific/Pacific-highway face closeup.jpg";
import Isobar from "../media/products/pacific/Pacific-isobar closeup.jpg";
import Jetstream from "../media/products/pacific/Pacific-jetstream closeup.jpg";
import Milkyway from "../media/products/pacific/Pacific-milkyway face closeup.jpg";
// earthen imports
import Basalt from "../media/products/earthen/Earthen_Basalt.jpg";
import Fired from "../media/products/earthen/Earthen_Fired.jpg";
import EarthenOcean from "../media/products/earthen/Earthen_Ocean.jpg";
import Raku from "../media/products/earthen/Earthen_Raku.jpg";
import Scoria from "../media/products/earthen/Earthen_Scoria.jpg";
import Stone from "../media/products/earthen/Earthen_Stone.jpg";
// navigator imports
import Beacon from "../media/products/navigator/Navigator-beacon closeup.jpg";
import Blake from "../media/products/navigator/Navigator-blake closeup.jpg";
import Endeavour from "../media/products/navigator/Navigator-endeavour closeup.jpg";
import Kupe from "../media/products/navigator/Navigator-kupe closeup.jpg";
import Latitude from "../media/products/navigator/Navigator-latitude closeup.jpg";
import Milford from "../media/products/navigator/Navigator-milford closeup.jpg";
import Scott from "../media/products/navigator/Navigator-scott closeup.jpg";
import Shackleton from "../media/products/navigator/Navigator-shackleton closeup.jpg";
import Tasman from "../media/products/navigator/Navigator-tasman closeup.jpg";

const NEW_RANGER = [
  {
    title: "Antigua",
    key: 10,
    image: Antigua,
  },
  {
    title: "Black",
    key: 20,
    image: Black,
  },
  {
    title: "Bordeaux",
    key: 4,
    image: Bordeaux,
  },
  {
    title: "Breeze",
    key: 14,
    image: Breeze,
  },
  {
    title: "Charade Navy",
    key: 8,
    image: CharadeNavy,
  },
  {
    title: "Charcoal",
    key: 19,
    image: Charcoal,
  },
  {
    title: "Classic Silver",
    key: 16,
    image: ClassicSilver,
  },
  {
    title: "Domingo",
    key: 2,
    image: Domingo,
  },
  {
    title: "Fairway",
    key: 11,
    image: Fairway,
  },
  {
    title: "Horizon Jason",
    key: 5,
    image: Horizon,
  },
  {
    title: "Kowhai",
    key: 12,
    image: Kowhai,
  },
  {
    title: "Natural",
    key: 13,
    image: Natural,
  },
  {
    title: "Red",
    key: 1,
    image: Red,
  },
  {
    title: "Royal Capri",
    key: 6,
    image: RoyalCapri,
  },
  {
    title: "Sterling/ Citrus",
    key: 17,
    image: Citrus,
  },
  {
    title: "Rustica",
    key: 3,
    image: Rustica,
  },
  {
    title: "Silver Grey",
    key: 15,
    image: SilverGrey,
  },
  {
    title: "Solar Blue",
    key: 7,
    image: SolarBlue,
  },
  {
    title: "Storm",
    key: 18,
    image: Storm,
  },
  {
    title: "Orange",
    key: 0,
    image: Tangerine,
  },
  {
    title: "Amarillo Vegas",
    key: 9,
    image: VegasAmarillo,
  },
];
const NEW_RANGER_INFO = {
  title: "new ranger",
  default: Natural,
  description:
    "New Ranger is 153cm wide, 100,000 martindale rubs, 100% polyester and inherently displays all the necessary characteristics of the 'high-performance' required for commercial applications on seating, partitioning, screens, panels and pinboards.",
  range: NEW_RANGER,
  url: "new-ranger",
  key: 0,
  count: NEW_RANGER.length,
};

const NEO_CONNECT = [
  {
    title: "Gazelle",
    key: 0,
    image: Gazelle,
  },
  {
    title: "Smoke",
    key: 1,
    image: Smoke,
  },
  {
    title: "Marlin",
    key: 2,
    image: Marlin,
  },
  {
    title: "Clarity",
    key: 3,
    image: Clarity,
  },
  {
    title: "Gunmetal",
    key: 4,
    image: Gunmetal,
  },
  {
    title: "Chess",
    key: 5,
    image: Chess,
  },
  {
    title: "Control",
    key: 6,
    image: Control,
  },
  {
    title: "Amazon Venus",
    key: 7,
    image: Amazon,
  },
  {
    title: "Riviera Beyond",
    key: 8,
    image: Beyond,
  },
  {
    title: "Cornflower",
    key: 9,
    image: Cornflower,
  },
  {
    title: "Motive",
    key: 10,
    image: Motive,
  },
];
const NEO_CONNECT_INFO = {
  title: "neo connect",
  default: Marlin,
  description:
    "Neo Connect is 153cm wide, 90,000 martindale rubs, 100% polyester and inherently displays all the necessary characteristics of the 'high-performance' required for commercial applications on seating, partitioning, screens, panels and pinboards.",
  range: NEO_CONNECT,
  url: "neo-connect",
  key: 1,
  count: NEO_CONNECT.length,
};

const NEW_GEM = [
  {
    title: "Brave",
    key: 8,
    image: Brave,
  },
  {
    title: "Coral",
    key: 0,
    image: Coral,
  },
  {
    title: "Zest",
    key: 1,
    image: Zest,
  },
  {
    title: "Emerald",
    key: 2,
    image: Emerald,
  },
  {
    title: "Media",
    key: 3,
    image: Media,
  },
  {
    title: "Sapphire",
    key: 4,
    image: Sapphire,
  },
  {
    title: "Pearl",
    key: 5,
    image: Pearl,
  },
  {
    title: "Opal",
    key: 6,
    image: Opal,
  },
  {
    title: "Platinum",
    key: 7,
    image: Platinum,
  },
  {
    title: "Onyx",
    key: 9,
    image: Onyx,
  },
];
const NEW_GEM_INFO = {
  title: "new gem",
  default: Sapphire,
  description:
    "New Gem is 183cm wide, 100% polyester, 2-tone, 60,000 martindale rubs, and inherently displays all the necessary characteristics of the 'high-performance' required for commercial applications on seating, partitioning, screens, panels and pinboards.",
  range: NEW_GEM,
  url: "new-gem",
  key: 2,
  count: NEW_GEM.length,
};

const FOCUS = [
  {
    title: "Tangerine",
    key: 0,
    image: FocusTangerine,
  },
  {
    title: "Citrus",
    key: 1,
    image: FocusCitrus,
  },
  {
    title: "Gravel",
    key: 2,
    image: Gravel,
  },
  {
    title: "Flame",
    key: 3,
    image: Flame,
  },
  {
    title: "Apple",
    key: 4,
    image: Apple,
  },
  {
    title: "Slate",
    key: 6,
    image: Slate,
  },
  {
    title: "Navy",
    key: 9,
    image: Navy,
  },
  {
    title: "Diesel",
    key: 10,
    image: Diesel,
  },
  {
    title: "Coffee",
    key: 11,
    image: Coffee,
  },
  {
    title: "Mermaid",
    key: 13,
    image: Mermaid,
  },
  {
    title: "Magenta",
    key: 14,
    image: Magenta,
  },
  {
    title: "Jet",
    key: 16,
    image: Jet,
  },
];
const FOCUS_INFO = {
  title: "focus",
  default: Magenta,
  description:
    "Focus is an eco-conscious upholstery fabric in a timeless crepe weave. 100% compostable fabric, 100% Pure New Zealand Wool, Sustainable Manufacturing, Non-metallic dyestuffs and Fully compostable - back to earth to close the loop.",
  range: FOCUS,
  url: "focus",
  key: 3,
  count: FOCUS.length,
};

const ULTRA = [
  {
    title: "Yellow",
    key: 0,
    image: Yellow,
  },
  {
    title: "Blue",
    key: 1,
    image: Blue,
  },
  {
    title: "Navy",
    key: 2,
    image: UltraNavy,
  },
  {
    title: "Purple",
    key: 3,
    image: Purple,
  },
  {
    title: "Taupe",
    key: 4,
    image: Taupe,
  },
  {
    title: "Port",
    key: 5,
    image: Port,
  },
  {
    title: "Red",
    key: 6,
    image: UltraRed,
  },
  {
    title: "Orange",
    key: 7,
    image: Orange,
  },
  {
    title: "Stingray",
    key: 8,
    image: Stingray,
  },
  {
    title: "Charcoal",
    key: 9,
    image: UltraCharcoal,
  },
  {
    title: "Black",
    key: 10,
    image: UltraBlack,
  },
  {
    title: "Polar",
    key: 11,
    image: Polar,
  },
  {
    title: "Cream",
    key: 12,
    image: Cream,
  },
  {
    title: "Pearl",
    key: 13,
    image: UltraPearl,
  },
  {
    title: "Quicksilver",
    key: 14,
    image: Quicksilver,
  },
];
const ULTRA_INFO = {
  title: "ultra vinyl",
  default: UltraPearl,
  description:
    "Ultra is a heavy duty commercial PVC vinyl developed for the tough marine, automotive and commercial furniture markets with the benefits of indoor/outdoor and anti-microbial properties for healthcare & aged care, science & university laboratories, cafes & bars and many other demanding environments while also providing the luxurious appearance and supple handle of a fine leather in a broad colour range, and with a two year manufacturers warranty.",
  range: ULTRA,
  url: "ultra",
  key: 4,
  count: ULTRA.length,
};

const PEBBLE = [
  {
    title: "Polar",
    key: 0,
    image: PebblePolar,
  },
  {
    title: "Cream",
    key: 1,
    image: PebbleCream,
  },
  {
    title: "Beige",
    key: 2,
    image: Beige,
  },
  {
    title: "Sand",
    key: 3,
    image: Sand,
  },
  {
    title: "Tan",
    key: 4,
    image: Tan,
  },
  {
    title: "Blue",
    key: 5,
    image: PebbleBlue,
  },
  {
    title: "Navy",
    key: 6,
    image: PebbleNavy,
  },
  {
    title: "Black",
    key: 7,
    image: PebbleBlack,
  },
  {
    title: "Charcoal",
    key: 8,
    image: PebbleCharcoal,
  },
  {
    title: "Grey",
    key: 9,
    image: PebbleGrey,
  },
  {
    title: "Chocolate",
    key: 10,
    image: Chocolate,
  },
];
const PEBBLE_INFO = {
  title: "pebble vinyl",
  default: PebbleBlue,
  description:
    "Pebble is a heavy duty commercial PVC vinyl developed for the tough marine, automotive and commercial furniture markets with the benefits of indoor/outdoor and anti-microbial properties for healthcare & aged care, science & university laboratories, cafes & bars and many other demanding environments while also providing the luxurious appearance and supple handle of a fine leather in a broad colour range, and with a two year manufacturers warranty.",
  range: PEBBLE,
  url: "pebble",
  key: 5,
  count: PEBBLE.length,
};

const KAPA = [
  {
    title: "Drift",
    key: 0,
    image: Drift,
  },
  {
    title: "Ash",
    key: 1,
    image: Ash,
  },
  {
    title: "Ocean",
    key: 2,
    image: Ocean,
  },
  {
    title: "Flax",
    key: 3,
    image: Flax,
  },
];
const KAPA_INFO = {
  title: "kapa",
  default: Ocean,
  description:
    "KAPA is a NZ MADE (reversible) Yarn Dyed wool jacquard with the Environmental Choice NZ certification. This lively design is based around the unique natural Tapa cloth pattern, incorporating the colourful pacific culture and their island traditions.",
  range: KAPA,
  url: "kapa",
  key: 6,
  count: KAPA.length,
};

const PACIFIC = [
  {
    title: "Isobar",
    key: 0,
    image: Isobar,
  },
  {
    title: "Breeze",
    key: 1,
    image: PacificBreeze,
  },
  {
    title: "Current",
    key: 2,
    image: Current,
  },
  {
    title: "Milkyway",
    key: 3,
    image: Milkyway,
  },
  {
    title: "Jetstream",
    key: 4,
    image: Jetstream,
  },
  {
    title: "Highhway",
    key: 5,
    image: Highway,
  },
];
const PACIFIC_INFO = {
  title: "pacific",
  default: Milkyway,
  description:
    "Pacific is a NZ MADE, Yarn Dyed wool jacquard with the Environmental Choice NZ certification. This design, as the name suggests, is themed around the natural elements of the Pacific, reminiscent of paua shells, ocean swells, sea breezes, night skies and the cultures of the islands of the Pacific.",
  range: PACIFIC,
  url: "pacific",
  key: 7,
  count: PACIFIC.length,
};

const EARTHEN = [
  {
    title: "Stone",
    key: 0,
    image: Stone,
  },
  {
    title: "Basalt",
    key: 1,
    image: Basalt,
  },
  {
    title: "Raku",
    key: 2,
    image: Raku,
  },
  {
    title: "Ocean",
    key: 3,
    image: EarthenOcean,
  },
  {
    title: "Fired",
    key: 4,
    image: Fired,
  },
  {
    title: "Scoria",
    key: 5,
    image: Scoria,
  },
];
const EARTHEN_INFO = {
  title: "earthen",
  default: Fired,
  description:
    "NZ Environmental Choice certified, Earthen has been designed and manufactured here in New Zealand for both the international and local markets. With its sophisticated appearance, high abrasion resistance, quality and comfort, Earthen will suit seating and wall covering environments where frequent use, high performance, durability, comfort and fashion are all important. Contributing factors to the environment benefits are the rapidly renewable and sustainable aspects of wool and the environmental requirements for green buildings, government and corporate business where sustainability and biodegradability are key elements.",
  range: EARTHEN,
  url: "earthen",
  key: 8,
  count: EARTHEN.length,
};

const NAVIGATOR = [
  {
    title: "Shackleton",
    key: 0,
    image: Shackleton,
  },
  {
    title: "Scott",
    key: 1,
    image: Scott,
  },
  {
    title: "Milford",
    key: 2,
    image: Milford,
  },
  {
    title: "Beacon",
    key: 3,
    image: Beacon,
  },
  {
    title: "Kupe",
    key: 4,
    image: Kupe,
  },
  {
    title: "Endeavour",
    key: 5,
    image: Endeavour,
  },
  {
    title: "Latitude",
    key: 6,
    image: Latitude,
  },
  {
    title: "Tasman",
    key: 7,
    image: Tasman,
  },
  {
    title: "Blake",
    key: 8,
    image: Blake,
  },
];
const NAVIGATOR_INFO = {
  title: "navigator",
  default: Latitude,
  description:
    "Navigator is a New Zealand-made heavy duty upholstery wool, specifically developed for the contract market. This quality wool product is produced by an 'Environmental Choice New Zealand-Certified' mill.",
  range: NAVIGATOR,
  url: "navigator",
  key: 9,
  count: NAVIGATOR.length,
};

const CATALOGUE = [
  NEW_RANGER_INFO,
  NEO_CONNECT_INFO,
  NEW_GEM_INFO,
  FOCUS_INFO,
  ULTRA_INFO,
  PEBBLE_INFO,
  KAPA_INFO,
  PACIFIC_INFO,
  EARTHEN_INFO,
  NAVIGATOR_INFO,
];

export default CATALOGUE;
