import React from "react";

import ClothProjectImages from "./ClothProjectImages";

const clothProjectImages = ClothProjectImages; // takes an image from cloth file array and changes it to the variable

// function creates slides to be displayed onto home page
// sliding is done in css
function ClothSlide() {
  const clothProjectSlides = clothProjectImages.map((slide) => (
    <div className="cloth-project-slide" key={slide.key}>
      <img className="cloth-slide" alt="" src={slide.images} />
    </div>
  ));
  return (
    <div className="cloth-project-slide-container">{clothProjectSlides}</div>
  );
}

export default ClothSlide;
