import React, { useEffect } from "react";
import ProjectSlide from "../components/projectSlideshow/ProjectSlideFixed";

// import New Ranger downloads
import NewRangerSC from "../media/downloads/New Ranger.jpg";
import NewRangerTest from "../media/downloads/New Ranger AS1530.3 test results 2017.pdf";
import NewRangerIS from "../media/downloads/New Ranger Specs 2018.pdf";
// import Neo Connect downloads
import NeoConnectSC from "../media/downloads/Neo Connect.jpg";
import NeoConnectIS from "../media/downloads/Neo Connect Specs 2018.pdf";
// import New Gem downloads
import NewGemSC from "../media/downloads/New Gem.jpg";
import NewGemIS from "../media/downloads/New Gem Specs 2018 (002).pdf";
// import Focus downloads
import FocusSC from "../media/downloads/Focus.jpg";
import FocusIS from "../media/downloads/Focus Shade Card - web.pdf";
// import Ultra downloads
import UltraSC from "../media/downloads/Ultra Vinyl.jpg";
import UltraIS from "../media/downloads/Ultra Shade Card-web.pdf";
// import Pebble downloads
import PebbleSC from "../media/downloads/Pebble Vinyl.jpg";
import PebbleIS from "../media/downloads/Pebble Shade Card-web.pdf";
// import Kapa downloads
import KapaIS from "../media/downloads/Kapa Shade Card - web.pdf";
// import Pacific downloads
import PacificIS from "../media/downloads/Pacific Shade Card - web.pdf";
// import Earthern downloads
import EarthernSC from "../media/downloads/earthen cols print.JPG";
import EarthenIS from "../media/downloads/Earthen shade card web.pdf";
// import Navigator downloads
import NavigatorSC from "../media/downloads/Navigator.jpg";
import NavigatorIS from "../media/downloads/Navigator Shade Card - web.pdf";
// import New Scotland downloads
import NewScotlandIS from "../media/downloads/New Scotland Specs 2018.pdf";

function Downloads() {
  // when mouse hovers over product display, a border will appear
  useEffect(() => {
    document.title = "Downloads | Cloth Commercial Textiles";
    window.scrollTo(0, 0); // always start at top of page
  }, []);
  function changeColor(e) {
    e.target.style.color = "#b3a26f";
  }
  // when mouse leaves box border will dissapear
  function removeColor(e) {
    e.target.style.color = "white";
  }

  return (
    <div className="downloads-container">
      <ProjectSlide />
      <div className="downloads-text">
        <h1>DOWNLOADS</h1>
        <p className="download-text">
          please find all our downloadable shadecards and information below.
        </p>
        <div className="download-list">
          <ul>
            <li>
              <a
                href={NewRangerSC}
                target="_blank"
                rel="noreferrer"
                onMouseEnter={changeColor}
                onMouseLeave={removeColor}
                className="downloadable"
              >
                new ranger shadecard
              </a>
            </li>
            <li>
              <a
                href={NewRangerIS}
                target="_blank"
                rel="noreferrer"
                onMouseEnter={changeColor}
                onMouseLeave={removeColor}
                className="downloadable"
              >
                new ranger information sheet
              </a>
            </li>
            <li>
              <a
                href={NewRangerTest}
                target="_blank"
                rel="noreferrer"
                onMouseEnter={changeColor}
                onMouseLeave={removeColor}
                className="downloadable"
              >
                new ranger test information
              </a>
            </li>
            <br />
            <li>
              <a
                href={NeoConnectSC}
                target="_blank"
                rel="noreferrer"
                onMouseEnter={changeColor}
                onMouseLeave={removeColor}
                className="downloadable"
              >
                neo connect shadecard
              </a>
            </li>
            <li>
              <a
                href={NeoConnectIS}
                target="_blank"
                rel="noreferrer"
                onMouseEnter={changeColor}
                onMouseLeave={removeColor}
                className="downloadable"
              >
                neo connect information sheet
              </a>
            </li>
            <br />
            <li>
              <a
                href={NewGemSC}
                target="_blank"
                rel="noreferrer"
                onMouseEnter={changeColor}
                onMouseLeave={removeColor}
                className="downloadable"
              >
                new gem shadecard
              </a>
            </li>
            <li>
              <a
                href={NewGemIS}
                target="_blank"
                rel="noreferrer"
                onMouseEnter={changeColor}
                onMouseLeave={removeColor}
                className="downloadable"
              >
                new gem information sheet
              </a>
            </li>
            <br />
            <li>
              <a
                href={FocusSC}
                target="_blank"
                rel="noreferrer"
                onMouseEnter={changeColor}
                onMouseLeave={removeColor}
                className="downloadable"
              >
                focus shadecard
              </a>
            </li>
            <li>
              <a
                href={FocusIS}
                target="_blank"
                rel="noreferrer"
                onMouseEnter={changeColor}
                onMouseLeave={removeColor}
                className="downloadable"
              >
                focus information sheet
              </a>
            </li>
            <br />
            <li>
              <a
                href={UltraSC}
                target="_blank"
                rel="noreferrer"
                onMouseEnter={changeColor}
                onMouseLeave={removeColor}
                className="downloadable"
              >
                ultra vinyl shadecard
              </a>
            </li>
            <li>
              <a
                href={UltraIS}
                target="_blank"
                rel="noreferrer"
                onMouseEnter={changeColor}
                onMouseLeave={removeColor}
                className="downloadable"
              >
                ultra vinyl information sheet
              </a>
            </li>
            <br />
            <li>
              <a
                href={PebbleSC}
                target="_blank"
                rel="noreferrer"
                onMouseEnter={changeColor}
                onMouseLeave={removeColor}
                className="downloadable"
              >
                pebble vinyl shadecard
              </a>
            </li>
            <li>
              <a
                href={PebbleIS}
                target="_blank"
                rel="noreferrer"
                onMouseEnter={changeColor}
                onMouseLeave={removeColor}
                className="downloadable"
              >
                pebble vinyl information sheet
              </a>
            </li>
            <br />
            <li>
              <a
                href={KapaIS}
                target="_blank"
                rel="noreferrer"
                onMouseEnter={changeColor}
                onMouseLeave={removeColor}
                className="downloadable"
              >
                kapa information sheet
              </a>
            </li>
            <br />
            <li>
              <a
                href={PacificIS}
                target="_blank"
                rel="noreferrer"
                onMouseEnter={changeColor}
                onMouseLeave={removeColor}
                className="downloadable"
              >
                pacific information sheet
              </a>
            </li>
            <br />
            <li>
              <a
                href={EarthernSC}
                target="_blank"
                rel="noreferrer"
                onMouseEnter={changeColor}
                onMouseLeave={removeColor}
                className="downloadable"
              >
                earthen shadecard
              </a>
            </li>
            <li>
              <a
                href={EarthenIS}
                target="_blank"
                rel="noreferrer"
                onMouseEnter={changeColor}
                onMouseLeave={removeColor}
                className="downloadable"
              >
                earthen information sheet
              </a>
            </li>
            <br />
            <li>
              <a
                href={NavigatorSC}
                target="_blank"
                rel="noreferrer"
                onMouseEnter={changeColor}
                onMouseLeave={removeColor}
                className="downloadable"
              >
                navigator shadecard
              </a>
            </li>
            <li>
              <a
                href={NavigatorIS}
                target="_blank"
                rel="noreferrer"
                onMouseEnter={changeColor}
                onMouseLeave={removeColor}
                className="downloadable"
              >
                navigator information sheet
              </a>
            </li>
            <br />
            <li>
              <a
                href={NewScotlandIS}
                target="_blank"
                rel="noreferrer"
                onMouseEnter={changeColor}
                onMouseLeave={removeColor}
                className="downloadable"
              >
                new scotland information sheet
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Downloads;
