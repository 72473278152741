import hospitalVinyl10 from "../../media/projects/cloth/hospital_vinyl_10.jpg";
import auckUni004 from "../../media/projects/cloth/Auck_Uni_Grads_Stage_004.jpg";
import mainfreight from "../../media/projects/cloth/Mainfreight05.jpg";
import connect from "../../media/projects/cloth/connect04.jpg";
import beanBags from "../../media/projects/cloth/Heavy Duty Bean Bag Setting.jpg";
import motiveTomato from "../../media/projects/cloth/motive + tomato chairs & sofa 22.jpg";
import redChair from "../../media/projects/cloth/Red chair (2).jpg";
// items in array to be used for slideshow
const ClothProjectImages = [
  {
    images: `${hospitalVinyl10}`,
    key: 0,
  },
  {
    images: `${auckUni004}`,
    key: 1,
  },
  {
    images: `${mainfreight}`,
    key: 2,
  },
  {
    images: `${connect}`,
    key: 3,
  },
  {
    images: `${beanBags}`,
    key: 4,
  },
  {
    images: `${motiveTomato}`,
    key: 5,
  },
  {
    images: `${redChair}`,
    key: 6,
  },
  {
    images: `${hospitalVinyl10}`,
    key: 7,
  },
  {
    images: `${auckUni004}`,
    key: 8,
  },
  {
    images: `${mainfreight}`,
    key: 9,
  },
];

export default ClothProjectImages;
